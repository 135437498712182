.text-field input {
    color: #eee; /* Cor do texto digitado */
    caret-color: #eee; /* Cor do cursor de digitação */
}

.text-field input::placeholder {
    color: #eee; /* Cor do placeholder */
    opacity: 0.7;
}

.text-field .MuiInputBase-input {
    border-bottom: 1px solid #eee;
}

.text-field input {
    color: #eee; /* Cor do texto digitado */
    caret-color: #eee; /* Cor do cursor de digitação */
}

.text-field input::placeholder {
    color: #eee; /* Cor do placeholder */
    opacity: 0.7;
}

.text-field .MuiInputBase-input {
    border-bottom: 1px solid #eee;
}

.MuiInputBase-input {
    color: #eee;
    caret-color: #eee;
}

.MuiInputBase-input::placeholder {
    color: #eee;
    opacity: 0.7;
}

.MuiInputBase-select::placeholder {
    color: #eee;
    opacity: 0.7;
}

.MuiSelect-select {
    color: #eee;
}

.MuiSelect-select.Mui-focused {
    color: #eee;
}

.MuiMenuItem-root {
    color: #0A1126; /* Para contraste no menu dropdown */
}
